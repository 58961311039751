import { navigate } from 'gatsby'
import React, { useEffect } from 'react'

export const Head = () => (
  <>
    <meta name="robots" content="noindex" />
  </>
)

export default () => {
  useEffect(() => {
    navigate(
      `https://docs.google.com/forms/d/e/1FAIpQLSf2ppMGmXbbgfhJ0mxPQQWMSrS-fYkOZ19d9y-0uUvsfB4tJA/viewform?usp=dialog`
    )
  }, [])
  return null
}
